import CView from '@/components/CView/CView.vue';
import { ERouteNames } from '@/router';
import { ESurveysActionType } from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SurveysView',
	components: {
		CView
	},
	data(): IInitialState {
		return {
			isProcessing: false,
			error: null
		};
	},
	computed: {
		surveysList() {
			return this.$store.state.surveysStore.surveysList;
		}
	},
	created() {
		this.fetchSurveysList();
	},
	methods: {
		onCreateNewSurveyButtonClick() {
			this.$router.push({ name: ERouteNames.CreateSurvey });
		},
		onEditSurveyButtonClick(surveyId: number) {
			this.$router.push({ name: ERouteNames.Survey, params: { id: surveyId } });
		},
		fetchSurveysList() {
			this.$data.isProcessing = true;

			this.$store.dispatch(ESurveysActionType.GET_SURVEYS_LIST)
				.catch(({ response }: { response: Response }) => {
					this.$data.error = {
						status: response.status,
						statusText: response.statusText
					};
				}).finally(() => {
					this.$data.isProcessing = false;
				});
		}
	}
});

interface IInitialState {
	isProcessing: boolean;
	error: IError | null;
}

interface IError {
	status: number;
	statusText: string;
}
