import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29c01902"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "is-flex tags-container mt-3"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SSpinner = _resolveComponent("SSpinner")!
  const _component_SNotification = _resolveComponent("SNotification")!
  const _component_STags = _resolveComponent("STags")!
  const _component_SListItem = _resolveComponent("SListItem")!
  const _component_CView = _resolveComponent("CView")!

  return (_openBlock(), _createBlock(_component_CView, {
    title: "Surveys list",
    "button-props": {
			buttonText: 'Create survey',
			color: 'link',
			onClick: _ctx.surveysList && _ctx.onCreateNewSurveyButtonClick,
		}
  }, {
    default: _withCtx(() => [
      (_ctx.isProcessing)
        ? (_openBlock(), _createBlock(_component_SSpinner, { key: 0 }))
        : (_ctx.error)
          ? (_openBlock(), _createBlock(_component_SNotification, {
              key: 1,
              title: `Fetch surveys failed: ${_ctx.error.status} - ${_ctx.error.statusText}`,
              centered: "",
              danger: ""
            }, null, 8, ["title"]))
          : (!_ctx.surveysList)
            ? (_openBlock(), _createBlock(_component_SNotification, {
                key: 2,
                title: "There are no surveys",
                "on-button-click": _ctx.onCreateNewSurveyButtonClick,
                "button-text": "Create survey",
                centered: ""
              }, null, 8, ["on-button-click"]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.surveysList, (survey) => {
                return (_openBlock(), _createBlock(_component_SListItem, {
                  key: survey.id,
                  class: "is-clickable",
                  title: survey.name,
                  description: survey.description,
                  onClick: ($event: any) => (_ctx.onEditSurveyButtonClick(survey.id))
                }, {
                  default: _withCtx(() => [
                    (survey.tagsList.length || survey.skillsList.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          (survey.tagsList.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_STags, {
                                  label: "Tags list:",
                                  "tags-list": survey.tagsList,
                                  "small-label": ""
                                }, null, 8, ["tags-list"])
                              ]))
                            : _createCommentVNode("", true),
                          (survey.skillsList.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createVNode(_component_STags, {
                                  label: "Skills list:",
                                  "tags-list": survey.skillsList,
                                  "small-label": ""
                                }, null, 8, ["tags-list"])
                              ]))
                            : _createCommentVNode("", true),
                          (survey.time)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(_component_STags, {
                                  label: "Time:",
                                  "tags-list": [survey.time],
                                  "small-label": ""
                                }, null, 8, ["tags-list"])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["title", "description", "onClick"]))
              }), 128))
    ]),
    _: 1
  }, 8, ["button-props"]))
}