import { defineComponent } from 'vue';

export default defineComponent({
	name: 'CView',
	props: {
		title: {
			type: String,
			required: true
		},
		subTitle: {
			type: String,
			default: undefined
		},
		buttonProps: {
			type: Object,
			default: undefined
		},
		buttonWarning: {
			type: String,
			default: undefined
		}
	}
});
