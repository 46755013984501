import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa9deb64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section p-4" }
const _hoisted_2 = { class: "title is-4" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle is-6"
}
const _hoisted_4 = { class: "is-flex is-align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "mr-2 has-text-warning-dark small-text"
}
const _hoisted_6 = { class: "content is-normal px-4 pt-5 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.subTitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subTitle), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.buttonWarning)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.buttonWarning), 1))
          : _createCommentVNode("", true),
        (_ctx.buttonProps)
          ? (_openBlock(), _createBlock(_component_SButton, _normalizeProps(_mergeProps({ key: 1 }, _ctx.buttonProps)), null, 16))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 64))
}